<template>
  <v-btn
      id="botao-whatsapp"
      ref="whatsapp"
      :href="getLinkWhatsapp"
      :size="isMobile ? 48 : 72"
      :style="{ 'font-size': isMobile ? '20px' : '30px'}"
      aria-label="Whatsapp da Sempre Internet"
      class="botao-whatsapp"
      color="success"
      elevation="20"
      style="color: white !important; text-decoration: none !important; z-index: 1000"
      target="_blank"
      variant="elevated">
    <v-icon>mdi-whatsapp</v-icon>
  </v-btn>
</template>
<script lang="ts">

interface Feriado {
  date: string;
  name: string;
  type: string
}

export default defineComponent({
  data: () => ({
    feriados: [] as Feriado[]
  }),
  computed: {
    isMobile() {
      return this.$vuetify.display.smAndDown
    },

    getLinkWhatsapp() {
      return this.isDiaUtil()
          ? 'https://api.whatsapp.com/send?phone=558003000800&text=Olá, acessei o site e gostaria de um atendimento!'
          : 'https://api.whatsapp.com/send?phone=553133607639&text=Olá, acessei o site e gostaria de um atendimento!';
    },
  },
  methods: {
    // Utilizado para que o botão de Whatsapp possa ser clicado programaticamente
    // por componentes externos
    clicarNoBotao() {
      this.$refs.whatsapp.$el.click();
    },

    async getFeriados() {
      const dataAtual = new Date();
      const anoAtual = dataAtual.getFullYear();
      try {
        const {data, pending, error, refresh} = await useFetch(`https://brasilapi.com.br/api/feriados/v1/${anoAtual}`, {
          key: `feriados-${anoAtual}`,
          deep: false,
          getCachedData: key => this.$nuxt.isHydrating ? this.$nuxt.payload.data[key] : this.$nuxt.static.data[key]
        });
        if (!data.value) {
          // Os dados não estão em cache, então busque na API
          await refresh()
        } else {
          // Os dados estão em cache
          this.feriados = data.value;
        }
      } catch (e) {
        console.error('Erro ao buscar feriados', e);
      }
    },

    isDiaUtil() {
      if (this.isFeriado()) {
        return false;
      }
      const hoje = new Date();
      const dia = hoje.getDay();
      const hora = hoje.getHours();
      const minutos = hoje.getMinutes();

      // Verificar se é dia da semana e está dentro do horário comercial
      if (dia >= 1 && dia <= 5) {
        if ((hora > 7 || (hora === 7 && minutos >= 30)) && hora < 20) {
          return true;
        }
      }
      // Verificar se é sábado e está dentro do horário comercial
      else if (dia === 6) {
        if ((hora > 7 || (hora === 7 && minutos >= 59)) && hora < 13) {
          return true;
        }
      }
      // Verificar se é domingo
      else if (dia === 0) {
        return false; // Domingo não é horário comercial
      }
      // Se não for nenhum dos casos acima, não está em horário comercial
      else {
        return false; // Feriado não é horário comercial
      }
    },

    isFeriado() {
      const dataAtual = new Date();
      const dia = String(dataAtual.getDate()).padStart(2, '0');
      const mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
      const dataFormatada = `${dataAtual.getFullYear()}-${mes}-${dia}`;
      return this.feriados.some(feriado => feriado.date === dataFormatada);
    },
  },
  async created() {
    await this.getFeriados();
  }
});
</script>
<style scoped>
.botao-whatsapp {
  border-radius: 50%;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.botao-whatsapp:hover {
  box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 10px 1px !important;
}
</style>
